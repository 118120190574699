<template>
  <v-container>
    <loan-type-list/>
  </v-container>
</template>

<script>
import LoanTypeList from "@/components/app_setting/loan_type/LoanTypeList";

export default {
  name: "LoanTypePage",
  components: {
    LoanTypeList,
  },
  mounted: () => {
    document.title = 'Agrani Bank Legal Suit System | Districts'
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

</style>